
// jQuery(document).ready(function() {
module.exports = () => {

    jQuery(window).load(function() {
        
        // Register GSAP plugins
        gsap.registerPlugin(ScrollSmoother, ScrollTrigger, SplitText, TextPlugin, EasePack);
    
        // Skew on scroll effect
        let skewSetter = gsap.quickTo('.skew-on-scroll', 'skewY');
        let skewReverseSetter = gsap.quickTo('.skew-on-scroll-reverse', 'skewY');
        let skewClamp = gsap.utils.clamp(-5, 5); // min and max values skew can have
            
    
        // scroll smoother
        // let smoother = ScrollSmoother.create({
        //     smooth: 0, 
        //     smoothTouch: 0, // off for touch screen
        //     effects: true,
        //     onUpdate: self => {
        //         skewSetter(skewClamp(self.getVelocity() / 100));
        //         skewReverseSetter(skewClamp(- self.getVelocity() / 100));
        //     },
        //     onStop: () => skewSetter(0)
        // });
    
    
        // // parallax effect
        // smoother.effects('.parallax img', {
        //     speed: 'auto', // good for parallax if set to auto
        // });
    
    
    
    
    
        // FADE INS
        gsap.utils.toArray('.fade-in').forEach(el => {
            let delay = el.dataset.delay;
            if (delay === undefined || isNaN(delay)) {
                delay = 0;
            }
            let time = Number(el.dataset.time);
            if (time === undefined || isNaN(time)) {
                time = 1.2
            }
            gsap.to(el, time, {
                scrollTrigger: el,
                opacity: '1',
                delay: delay
            });
        });
    
    
        gsap.utils.toArray('.fade-from-top').forEach(el => {
            let delay = el.dataset.delay;
            if (delay === undefined || isNaN(delay)) {
                delay = 0;
            }
            let time = Number(el.dataset.time);
            if (time === undefined || isNaN(time)) {
                time = .8
            }
            gsap.to(el, time, {
                scrollTrigger: el,
                top: '0px',
                opacity: '1',
                delay: delay
            });
        });
        gsap.utils.toArray('.fade-from-bottom').forEach(el => {
            let delay = el.dataset.delay;
            if (delay === undefined || isNaN(delay)) {
                delay = 0;
            }
            let time = Number(el.dataset.time);
            if (time === undefined || isNaN(time)) {
                time = .8
            }
            gsap.to(el, time, {
                scrollTrigger: el,
                bottom: '0px',
                opacity: '1',
                delay: delay
            });
        });
        gsap.utils.toArray('.fade-from-left').forEach(el => {
            let delay = el.dataset.delay;
            if (delay === undefined || isNaN(delay)) {
                delay = 0;
            }
            let time = Number(el.dataset.time);
            if (time === undefined || isNaN(time)) {
                time = .8
            }
            gsap.to(el, time, {
                scrollTrigger: el,
                left: '0px',
                opacity: '1',
                delay: delay
            });
        });
        gsap.utils.toArray('.fade-from-right').forEach(el => {
            let delay = el.dataset.delay;
            if (delay === undefined || isNaN(delay)) {
                delay = 0;
            }
            let time = Number(el.dataset.time);
            if (time === undefined || isNaN(time)) {
                time = .8
            }
            gsap.to(el, time, {
                scrollTrigger: el,
                right: '0px',
                opacity: '1',
            });
        });
        
        gsap.utils.toArray('.fade-words-from-top').forEach(el => {
            let stagger = Number(el.dataset.stagger);
            if (stagger === undefined || isNaN(stagger)) {
                stagger = 0.2;
            }
            let delay = Number(el.dataset.delay);
            if (delay === undefined || isNaN(delay)) {
                delay = 0;
            }
            // split text into lines and words
            let splitText = new SplitText(el, {type: 'lines,words'});
            gsap.from(splitText.words, {
                scrollTrigger: el,
                y: '-100%',
                opacity: '1',
                duration: .8,
                stagger: stagger,
                delay: delay,
                onComplete: () => splitText.revert()
            }, 0);
            gsap.set(el, {
                scrollTrigger: el,
                opacity: '1'
            }, 0);
        });
        gsap.utils.toArray('.fade-words-from-bottom').forEach(el => {
            let stagger = Number(el.dataset.stagger);
            if (stagger === undefined || isNaN(stagger)) {
                stagger = 0.2;
            }
            let delay = Number(el.dataset.delay);
            if (delay === undefined || isNaN(delay)) {
                delay = 0;
            }
            // split text into lines and words
            let splitText = new SplitText(el, {type: 'lines,words'});
            gsap.from(splitText.words, {
                scrollTrigger: el,
                y: '100%',
                opacity: '1',
                duration: .8,
                stagger: stagger,
                delay: delay,
                onComplete: () => splitText.revert()
            }, 0);
            gsap.set(el, {
                scrollTrigger: el,
                opacity: '1'
            }, 0);
        });
    
    
        gsap.utils.toArray('.fade-lines-from-top').forEach(el => {
            let stagger = Number(el.dataset.stagger);
            if (stagger === undefined || isNaN(stagger)) {
                stagger = 0.2;
            }
            let delay = Number(el.dataset.delay);
            if (delay === undefined || isNaN(delay)) {
                delay = 0;
            }
    
            let splitText = new SplitText(el, {type: "lines"});
            gsap.to(splitText.lines, {
                scrollTrigger: el,
                top: '0',
                opacity: '1',
                stagger: stagger,
                delay: delay,
                onComplete: () => splitText.revert()
            }, 0);
        })
        gsap.utils.toArray('.fade-lines-from-bottom').forEach(el => {
            let stagger = Number(el.dataset.stagger);
            if (stagger === undefined || isNaN(stagger)) {
                stagger = 0.2;
            }
            let delay = Number(el.dataset.delay);
            if (delay === undefined || isNaN(delay)) {
                delay = 0;
            }
    
            let splitText = new SplitText(el, {type: "lines"});
            gsap.to(splitText.lines, {
                scrollTrigger: el,
                top: '0',
                opacity: '1',
                stagger: stagger,
                delay: delay,
                onComplete: () => splitText.revert()
            }, 0);
        });
        gsap.utils.toArray('.fade-lines-from-left').forEach(el => {
            let stagger = Number(el.dataset.stagger);
            if (stagger === undefined || isNaN(stagger)) {
                stagger = 0.2;
            }
            let delay = Number(el.dataset.delay);
            if (delay === undefined || isNaN(delay)) {
                delay = 0;
            }
    
            let splitText = new SplitText(el, {type: "lines"});
            gsap.to(splitText.lines, {
                scrollTrigger: el,
                left: '0',
                opacity: '1',
                stagger: stagger,
                delay: delay,
                onComplete: () => splitText.revert()
            }, 0);
        });
        gsap.utils.toArray('.fade-lines-from-right').forEach(el => {
            let stagger = Number(el.dataset.stagger);
            if (stagger === undefined || isNaN(stagger)) {
                stagger = 0.2;
            }
            let delay = Number(el.dataset.delay);
            if (delay === undefined || isNaN(delay)) {
                delay = 0;
            }
    
            let splitText = new SplitText(el, {type: "lines"});
            gsap.to(splitText.lines, {
                scrollTrigger: el,
                left: '0',
                opacity: '1',
                stagger: stagger,
                delay: delay,
                onComplete: () => splitText.revert()
            }, 0);
        });
    
    
        gsap.utils.toArray('.fade-lines-from-bottom-skew').forEach(el => {
            let stagger = Number(el.dataset.stagger);
            if (stagger === undefined || isNaN(stagger)) {
                stagger = 0.2;
            }
            let delay = Number(el.dataset.delay);
            if (delay === undefined || isNaN(delay)) {
                delay = 0;
            }
    
            let splitText = new SplitText(el, {type: "lines"});
            gsap.from(splitText.lines, {
                scrollTrigger: el,
                y: '100px',
                opacity: '0',
                skewY: '3',
                stagger: stagger,
                delay: delay,
                onComplete: () => splitText.revert()
            });
        });
        gsap.utils.toArray('.fade-lines-from-top-skew').forEach(el => {
            let stagger = Number(el.dataset.stagger);
            if (stagger === undefined || isNaN(stagger)) {
                stagger = 0.2;
            }
            let delay = Number(el.dataset.delay);
            if (delay === undefined || isNaN(delay)) {
                delay = 0;
            }
    
            let splitText = new SplitText(el, {type: "lines"});
            gsap.from(splitText.lines, {
                scrollTrigger: el,
                y: '-100px',
                opacity: '0',
                skewY: '-3',
                stagger: stagger,
                delay: delay,
                onComplete: () => splitText.revert()
            });
        });
        
    
    
    
    
    
        // REVEALS
        // Reveal element
        gsap.utils.toArray('.reveal-from-top').forEach(el => {
            // split text into lines and words
            gsap.from(el.children, {
                scrollTrigger: el,
                y: '-100%',
                duration: .8
            }, 0);
        });
        gsap.utils.toArray('.reveal-from-bottom').forEach(el => {
            // split text into lines and words
            gsap.from(el.children, {
                scrollTrigger: el,
                y: '100%',
                duration: .8
            }, 0);
        });
        // Reveal words (data-stagger gets stagger)
        gsap.utils.toArray('.reveal-words-from-top').forEach(el => {
            // Get stagger if set
            let staggerVal = el.dataset.stagger
            if (el.dataset.stagger === undefined) {
                staggerVal = 0;
            }
    
            // split text into lines and words
            let splitText = new SplitText(el, {type: 'lines,words'});
            gsap.from(splitText.words, {
                scrollTrigger: el,
                y: '100%',
                duration: .8,
                stagger: staggerVal,
                onComplete: () => splitText.revert()
            }, 0);
            gsap.set(el, {
                scrollTrigger: el,
                opacity: '1'
            }, 0);
        });
        gsap.utils.toArray('.reveal-words-from-bottom').forEach(el => {
            // Get stagger if set
            let staggerVal = el.dataset.stagger
            if (el.dataset.stagger === undefined) {
                staggerVal = 0;
            }
    
            // split text into lines and words
            let splitText = new SplitText(el, {type: 'lines,words'});
            gsap.from(splitText.words, {
                scrollTrigger: el,
                y: '-100%',
                duration: .8,
                stagger: staggerVal,
                onComplete: () => splitText.revert()
            }, 0);
            gsap.set(el, {
                scrollTrigger: el,
                opacity: '1'
            }, 0);
        });
    
        // Reveal lines (data-stagger gets stagger)
        gsap.utils.toArray('.reveal-lines-from-top').forEach(el => {
            // Get stagger if set
            let staggerVal = el.dataset.stagger
            if (el.dataset.stagger === undefined) {
                staggerVal = 0;
            }
    
            // Split text into lines twice
            let splitText = new SplitText(el, {type: "lines"});
            let splitLines = new SplitText(splitText.lines, {type: "lines"});
            gsap.from(splitLines.lines, {
                scrollTrigger: el,
                duration: .6,
                y: "100%",
                stagger: staggerVal,
                onComplete: () => splitText.revert()
            }, 0);
            gsap.set(el, {
                scrollTrigger: el,
                opacity: '1'
            }, 0);
        });
        gsap.utils.toArray('.reveal-lines-from-bottom').forEach(el => {
            // Get stagger if set
            let staggerVal = el.dataset.stagger
            if (el.dataset.stagger === undefined) {
                staggerVal = 0;
            }
    
            // Split text into lines twice
            let splitText = new SplitText(el, {type: "lines"});
            let splitLines = new SplitText(splitText.lines, {type: "lines"});
            gsap.from(splitLines.lines, {
                scrollTrigger: el,
                duration: .6,
                y: "100%",
                stagger: staggerVal,
                onComplete: () => splitText.revert()
            }, 0);
            gsap.set(el, {
                scrollTrigger: el,
                opacity: '1'
            }, 0);
        });
        gsap.utils.toArray('.reveal-lines-from-left').forEach(el => {
            // Get stagger if set
            let staggerVal = el.dataset.stagger
            if (el.dataset.stagger === undefined) {
                staggerVal = 0;
            }
    
            // Split text into lines twice
            let splitText = new SplitText(el, {type: "lines"});
            let splitLines = new SplitText(splitText.lines, {type: "lines"});
            gsap.from(splitLines.lines, {
                scrollTrigger: el,
                duration: .6,
                x: "-100%",
                stagger: staggerVal,
                onComplete: () => splitText.revert()
            }, 0);
            gsap.set(el, {
                scrollTrigger: el,
                opacity: '1'
            }, 0);
        });
        gsap.utils.toArray('.reveal-lines-from-right').forEach(el => {
            // Get stagger if set
            let staggerVal = el.dataset.stagger
            if (el.dataset.stagger === undefined) {
                staggerVal = 0;
            }
    
            // Split text into lines twice
            let splitText = new SplitText(el, {type: "lines"});
            let splitLines = new SplitText(splitText.lines, {type: "lines"});
            gsap.from(splitLines.lines, {
                scrollTrigger: el,
                duration: .6,
                x: "100%",
                stagger: staggerVal,
                onComplete: () => splitText.revert()
            }, 0);
            gsap.set(el, {
                scrollTrigger: el,
                opacity: '1'
            }, 0);
        });
        
    
        // Reveal lines with skew (data-stagger gets stagger)
        gsap.utils.toArray('.reveal-lines-from-top-skew').forEach(el => {
            // Get stagger if set
            let staggerVal = el.dataset.stagger
            if (el.dataset.stagger === undefined) {
                staggerVal = 0;
            }
    
            // Split text into lines twice
            let splitText = new SplitText(el, {type: "lines"});
            let splitLines = new SplitText(splitText.lines, {type: "lines"});
            gsap.from(splitLines.lines, .5, {
                scrollTrigger: el,
                y: "-300%",
                skewY: '10',
                duration: .6,
                stagger: staggerVal,
                onComplete: () => splitText.revert()
            }, 0);
            gsap.set(el, {
                scrollTrigger: el,
                opacity: '1'
            }, 0)
        });
        gsap.utils.toArray('.reveal-lines-from-bottom-skew').forEach(el => {
            // Get stagger if set
            let staggerVal = el.dataset.stagger
            if (el.dataset.stagger === undefined) {
                staggerVal = 0;
            }
    
            // Split text into lines twice
            let splitText = new SplitText(el, {type: "lines"});
            let splitLines = new SplitText(splitText.lines, {type: "lines"});
            gsap.from(splitLines.lines, .5, {
                scrollTrigger: el,
                y: "300%",
                skewY: '10',
                duration: .6,
                stagger: staggerVal,
                onComplete: () => splitText.revert()
            }, 0);
            gsap.set(el, {
                scrollTrigger: el,
                opacity: '1'
            }, 0)
        });
        
        
    
    
    
    
        // RISE TO ANIMATIONS
        // Fade in elements from no position
        gsap.utils.toArray('.to-position').forEach(el => {
            let delay = el.dataset.delay;
            if (el.dataset.delay === undefined) {
                delay = 0;
            }
            let amount = el.dataset.amount;
            if (amount === undefined) {
                amount = '20px';
            }
            gsap.from(el, 1.2, {
                scrollTrigger: el,
                top: amount,
                delay: delay
            });
        });
        gsap.utils.toArray('.words-to-position').forEach(el => {
            // Get stagger if set
            let staggerVal = el.dataset.stagger
            if (staggerVal === undefined) {
                staggerVal = 0;
            }
    
            // Get amount if set
            let amountVal = el.dataset.amount
            if (amountVal === undefined) {
                amountVal = '20px'
            }
    
            // split text into lines and words
            let splitText = new SplitText(el, {type: 'lines,words'});
            gsap.from(splitText.words, {
                scrollTrigger: el,
                top: amountVal,
                duration: .8,
                stagger: staggerVal,
                onComplete: () => splitText.revert()
            }, 0);
        });
        gsap.utils.toArray('.lines-to-position').forEach(el => {
            // Get stagger if set
            let staggerVal = el.dataset.stagger
            if (el.dataset.stagger === undefined) {
                staggerVal = 0;
            }
    
            // Get amount if set
            let amountVal = el.dataset.amount
            if (amountVal === undefined) {
                amountVal = '20px'
            }
    
            // Split text into lines twice
            let splitText = new SplitText(el, {type: "lines"});
            let splitLines = new SplitText(splitText.lines, {type: "lines"});
            gsap.from(splitLines.lines, {
                scrollTrigger: el,
                duration: .6,
                top: amountVal,
                stagger: staggerVal,
                onComplete: () => splitText.revert()
            }, 0);
            gsap.set(el, {
                scrollTrigger: el,
                opacity: '1'
            }, 0);
        });
    
        
        
        
        // Cursor text
        gsap.utils.toArray('.cursor-text b:nth-of-type(1)').forEach(el => {
            // create master timeline
            let masterCursorTl = gsap.timeline({repeat: -1});
    
            // get list of words and trim any whitespace
            let words = el.dataset.words.split(',');
            words = words.map(w => w.trim());
    
            // create tl for each word for each el
            words.forEach(word => {
                let tl = gsap.timeline({repeat: 1, repeatDelay: 1.3, yoyo: true});
                tl.to(el, {
                    duration: 1,
                    text: word,
                    ease: 'none'
                });
                masterCursorTl.add(tl);
            });
        });
        // actual cursor from cursor text
        // gsap.utils.toArray('cursor-text b:nth-of-type(2)').forEach(el);
        gsap.from('.cursor-text b:nth-of-type(2)', {
            opacity: '0',
            repeat: -1,
            yoyo: true,
            delay: 0,
            duration: 0.3
        });
        
        
        
        
    
        // rotating text
        let rotators = document.getElementsByClassName('rotating-text');
        gsap.utils.toArray(rotators).forEach(rotator => {
            let targetEls = rotator.querySelectorAll('span');
            let targets = gsap.utils.toArray(targetEls);
            
            let dur = 0.5;
            let hold = 1;
            let outerTl = gsap.timeline();
            outerTl.set(rotator, {height: targets[0].offsetHeight});
            outerTl.to(rotator, {duration: 0.8, width: targets[0].offsetWidth });
            outerTl.set(rotator, {transition: '0.8s' });
    
            targets.forEach((obj, i) => {
                let tl = gsap.timeline({
                    delay: dur * i + hold * i + .4,
                    repeat: -1,
                    repeatDelay: (targets.length - 1) * (dur + hold) - dur,
                    defaults: {
                        ease: "none",
                        duration: dur
                    },
                });
    
                tl.from(obj, {yPercent: -100,  },0 );
                tl.set(obj.parentNode, { width: obj.offsetWidth, height: obj.offsetHeight}, 0);
                tl.to(obj, {yPercent: 100}, "+=" + hold);
            });
        });
    
    
        
    
        
    });

}    
