
/* --- SLIDER --- */
module.exports = () => {
	jQuery(document).ready(function () {

		jQuery('.slider').slick({
			dots: true,
			infinite: false,
			speed: 500,
			slidesToShow: 3,
			slidesToScroll: 3,
			arrows: false,
			centerPadding: '40px',
			autoplay: true,
			autoplaySpeed: 2000,
			mobileFirst: true,
			responsive: [
				{
					breakpoint: 900,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 2
					}
				},
				{
					breakpoint: 540,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1
					}
				}
				// You can unslick at a given breakpoint now by adding:
				// settings: "unslick"
				// instead of a settings object
			]
		});
		jQuery(window).resize(function(){
			jQuery('.slider').slick('resize'); // recalc on window resize
		});



		jQuery('.solutions_flex .tiles').slick({
			// dots: true,
			infinite: false,
			nextArrow: jQuery('.solutions_flex .slick-next'),
			prevArrow: jQuery('.solutions_flex .slick-prev'),
			speed: 500,
			slidesToShow: 1.05,
			slidesToScroll: 1,
			arrows: true,
			centerPadding: '40px',
			autoplay: false,
			mobileFirst: true,
			swipeToSlide: true
		});
		jQuery(window).resize(function(){
			jQuery('.slider').slick('resize'); // recalc on window resize
		});


		
		jQuery('.latest-slider').slick({
			dots: true,
			infinite: false,
			speed: 500,
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: false,
			centerPadding: '40px',
			autoplay: true,
			autoplaySpeed: 2000,
			mobileFirst: true,
			responsive: [
				{
					breakpoint: 900,
					settings: "unslick"
				},
				{
					breakpoint: 540,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 2
					}
				}
				// You can unslick at a given breakpoint now by adding:
				// settings: "unslick"
				// instead of a settings object
			]
		});
		jQuery(window).resize(function(){
			jQuery('.latest-slider').slick('resize'); // recalc on window resize
		});



		jQuery('.card-slider').slick({
			dots: true,
			infinite: false,
			speed: 500,
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: false,
			centerPadding: '40px',
			autoplay: true,
			autoplaySpeed: 4000,
			mobileFirst: true,

		});
		jQuery(window).resize(function(){
			jQuery('.card-slider').slick('resize'); // recalc on window resize
		});





		jQuery('.logos-slider').slick({
			speed: 9000,
			autoplay: true,
			autoplaySpeed: 0,
			cssEase: 'linear',
			slidesToShow: 1,
			slidesToScroll: 1,
			variableWidth: true,
			arrows: false
		}); 




		jQuery('.related-slider').slick({
			dots: true,
			infinite: false,
			speed: 500,
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: false,
			centerPadding: '40px',
			autoplay: true,
			autoplaySpeed: 2000,
			mobileFirst: true,
			responsive: [
				{
					breakpoint: 900,
					settings: "unslick"
				},
				{
					breakpoint: 540,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 2
					}
				}
				// You can unslick at a given breakpoint now by adding:
				// settings: "unslick"
				// instead of a settings object
			]
		});
		jQuery(window).resize(function(){
			jQuery('.related-slider').slick('resize'); // recalc on window resize
		});



	});
}